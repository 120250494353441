@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Inter",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.font-inter {
  font-family: "Inter", Inter;
}

input[type="email"],
input[type="text"],
input[type="password"],
textarea {
  border: none;
  outline: none;
}
.flowbite-pagination ul{
  display: flex;
}

.otp-input {
  width: 20px !important;
}
.test{
  padding:0px !important;
}
.phone-container {
  height: 2.75rem;
  border: 0px solid black !important;
  border-radius: 0.5rem !important;
}

.phone-input {
  width: 100% !important;
  height: 2.75rem !important;
  border: 1px solid #dfdfdf !important;
  border-radius: 0.5rem !important;
}
.phone-input:focus-within {
  border: 1px solid #55a8fd !important;
}

.error-phone-input {
  width: 100% !important;
  height: 2.75rem !important;
  border-radius: 0.5rem !important;
  border: 1px solid #d51d14 !important;
}

.flag-dropdown {
  border-right: 0px !important;
  border-left: 0px !important;
  border-color: transparent !important;
  background-color: transparent !important;
  box-shadow: none !important;
  margin-left: 5px;
}

.country-list {
  margin-left: -5px !important;
}

.scrollbar::-webkit-scrollbar {
  width: 0px;
}

.scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scrollbar::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 2px;
}

/* Slider */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-bottom: 2.5rem; */
  padding-left: 2rem;
  padding-right: 2rem;
}

@media (max-width: 700px) {
  .swiper-slide {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.swiper-pagination {
  /* background: #9b1111; */
  z-index: 100;
  /* position: absolute !important;
	top: 50% !important;
	left: 0px !important; */
  width: 100% !important;
  /* margin: 0; */
}

.swiper-pagination-bullet {
  border-radius: 10px !important;
  width: 2rem !important;
  height: 3px !important;
  opacity: 1 !important;
  background: rgba(255, 255, 255, 0.5) !important;
}

.swiper-pagination-bullet-active {
  background: #ffffff !important;
}

.swiper-button-next {
  position: absolute;
  margin: 0;
  right: 0px !important;
}

.swiper-button-prev {
  position: absolute;
  margin: 0;
  left: 0px !important;
}

.swiper-button-next::after {
  color: #acacac;
  font-size: 20px !important;
  font-weight: 900 !important;
}

.swiper-button-prev::after {
  color: #acacac;
  font-size: 20px !important;
  font-weight: 900 !important;
}
